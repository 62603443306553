import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar({ expand, setExpand, role }) {
  const { pathname } = useLocation();
  const toggleSidebar = (e) => {
    if (e.target.id.includes("sidebar")) return;
    setExpand((prev) => !prev);
  };

  const [delayed, setDelayed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (expand) {
        setDelayed(true);
      } else {
        setDelayed(false);
      }
    }, 300);
  }, [expand]);

  return (
    <div
      className={`fixed top-0 bg-black_1 h-[100vh] text-white py-[10px] border-white_1 z-[3000] transition-all ease-in-out delay-150 ${
        expand
          ? "w-[200px] custom2Xl:w-[250px] custom3Xl:w-[300px] transition-all ease-in-out delay-150"
          : "transition-all ease-in-out delay-150 w-[100px]"
      }`}
      onClick={toggleSidebar}
    >
      <div className="w-full flex justify-center items-center mb-[23px] mt-[43px]">
        <img
          src="./images/dashboard-logo.png"
          alt="logo"
          className="my-0 mx-auto h-[53px] w-[53px] group-hover:w-[90px] transition-all ease-in-out delay-150 group-hover:h-[90px]"
        />
      </div>
      <div className="h-[1px] bg-white w-[50px] mt-0 mx-auto mb-[43px] group-hover:w-[80%]"></div>
      <Link to={"/dashboard"} id="sidebar_link">
        <div
          className="flex flex-start items-center bg-#464646 pl-[5px] translate-x-[-4px] h-[55px] w-full cursor-pointer mb-[20px] transition-all ease-in-out delay-150 group-hover:w-[101%] group-hover:hover:w-[103%]"
          id="sidebar_menu"
        >
          <span
            className="black h-[55px] w-[5px] bg-[#f5cb5c]"
            id="sidebar_boarder"
          ></span>
          <div
            className={`h-[40px] w-[55px] bg-white flex items-center justify-center mr-[20px] ml-[5px] rounded-[5px] ${
              pathname === "/dashboard"
                ? "h-[56px] transition-all duration-150"
                : ""
            }`}
            id="sidebar_img_container"
          >
            <img src="./images/dashboardicon.png" alt="icon" id="sidebar_img" />
          </div>
          {delayed && (
            <p
              className={`absolute ${
                expand ? "left-[40%] opacity-100" : "left-[-200%] opacity-0"
              } transition-opacity duration-300`}
              id="sidebar_name"
            >
              Dashboard
            </p>
          )}
        </div>
      </Link>

      {role.name === "admin" || role.name === "analyst" ? (
        <Link to={"/report-builder"} id="sidebar_link">
          <div
            className="flex flex-start items-center bg-#464646 pl-[5px] translate-x-[-4px] h-[55px] w-full cursor-pointer mb-[20px] group-hover:w-[101%]"
            id="sidebar_menu"
          >
            <span
              className="black h-[55px] w-[5px] bg-[#f5cb5c]"
              id="sidebar_boarder"
            ></span>
            <div
              className={`h-[40px] w-[55px] bg-white flex items-center justify-center mr-[20px] ml-[5px] rounded-[5px] ${
                pathname === "/report-builder"
                  ? "h-[56px] transition-all duration-150"
                  : ""
              }`}
              id="sidebar_img_container"
            >
              <img
                src="./images/myreportsicon.png"
                alt="icon"
                id="sidebar_img"
              />
            </div>
            {delayed && (
              <p
                className={`absolute ${
                  expand ? "left-[40%] opacity-100" : "left-[-200%] opacity-0"
                } transition-opacity duration-300`}
                id="sidebar_name"
              >
                Report Builder
              </p>
            )}
          </div>
        </Link>
      ) : null}

      {role.name === "analyst" ||
      role.name === "admin" ||
      role.name === "manager" ? (
        <Link to={"/reports"} id="sidebar_link">
          <div
            className="flex flex-start items-center bg-#464646 pl-[5px] translate-x-[-4px] h-[55px] w-full cursor-pointer mb-[20px] group-hover:w-[101%]"
            id="sidebar_menu"
          >
            <span
              className="black h-[55px] w-[5px] bg-[#f5cb5c]"
              id="sidebar_boarder"
            ></span>
            <div
              className={`h-[40px] w-[55px] bg-white flex items-center justify-center mr-[20px] ml-[5px] rounded-[5px] ${
                pathname === "/reports"
                  ? "h-[56px] transition-all duration-150"
                  : ""
              }`}
              id="sidebar_img_container"
            >
              <img
                src="./images/newsfeedicon.png"
                alt="icon"
                id="sidebar_img"
              />
            </div>
            {delayed && (
              <p
                className={`absolute ${
                  expand ? "left-[40%] opacity-100" : "left-[-200%] opacity-0"
                } transition-opacity duration-300`}
                id="sidebar_name"
              >
                Saved Reports
              </p>
            )}
          </div>
        </Link>
      ) : null}

      {/* TODO: Comment out for now, needs for clarification */}
      {/* {(role.name === "admin") | (role.name === "billing manager") ? (
        <Link to={"/settings"} id="sidebar_link">
          <div
            className="flex flex-start items-center bg-#464646 pl-[5px] translate-x-[-4px] h-[55px] w-full cursor-pointer mb-[20px] group-hover:w-[101%]"
            id="sidebar_menu"
          >
            <span
              className="black h-[55px] w-[5px] bg-[#f5cb5c]"
              id="sidebar_boarder"
            ></span>
            <div
              className={`h-[40px] w-[55px] bg-white flex items-center justify-center mr-[20px] ml-[5px] rounded-[5px] ${
                pathname === "/settings"
                  ? "h-[56px] transition-all duration-150"
                  : ""
              }`}
              id="sidebar_img_container"
            >
              <img src="./images/settingicon.png" alt="icon" id="sidebar_img" />
            </div>
            {delayed && (
              <p
                className={`absolute ${
                  expand ? "left-[40%] opacity-100" : "left-[-200%] opacity-0"
                } transition-opacity duration-300`}
                id="sidebar_name"
              >
                Settings
              </p>
            )}
          </div>
        </Link>
      ) : null} */}
    </div>
  );
}

export default Sidebar;
