import { Link } from "react-router-dom";

export const Button = ({ children, className, onClick, href, ...rest }) => {
  if (href) {
    return (
      <Link
        className={`block w-full text-center h-[54px] text-white py-[14px] rounded-[5px] bg-blue_1 ${className}`}
        to={href}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`text-white h-[54px] py-[14px] rounded-[5px] bg-black_1 ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
