import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/svg/logo-removebg-preview.svg";
import { menuItems } from "../../../../utils/helpers";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function Header({ showMobileMenu, setShowMobileMenu }) {
  const handleViewMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  return (
    <header className="w-full border-b-4 border-b-[#464646]">
      <div className="px-5 lg:px-[100px]">
        <div className="flex flex-col lg:items-center justify-between customLg:flex-row">
          <Link to="/">
            <img
              src="./images/blackbook-logo.svg"
              className="object-contain w-[200px] sm:w-max  mt-4 lg:my-4"
              alt="Black_Book_logo"
            />
          </Link>
          <div className="hidden lg:flex flex-row gap-[25px]">
            <Link
              className="flex items-center hover:underline bg-[#464646] w-[245px] gap-[15px] py-[4px] px-[8px] h-[55px] relative"
              to="https://blackbookmarketresearch.com/download-our-survey-app"
            >
              <div className="absolute left-[4px] w-[8px] h-[55px] bg-[#F5CB5C]" />
              <div className="bg-white w-[65px] h-[47px] flex items-center justify-center rounded-[4px]">
                <img src="./icons/mobile.svg" alt="mobile_icon" />
              </div>
              <span className="shrink-0 text-[13px] leading-[18px] w-[122px] text-white font-normal font-sans">
                Download one of our Mobile Survey Apps
              </span>
            </Link>
            <Link
              className="flex items-center hover:underline bg-[#464646] w-[245px] gap-[15px] py-[4px] px-[8px] h-[55px] relative"
              to="https://blackbookmarketresearch.com/participate-in-an-online-survey"
            >
              <div className="absolute left-[4px] w-[8px] h-[55px] bg-[#1EA1F2]" />
              <div className="bg-white w-[65px] h-[47px] flex items-center justify-center rounded-[4px]">
                <img src="./icons/pc.svg" alt="mobile_icon" />
              </div>
              <span className="shrink-0 text-[13px] leading-[17px] w-[122px] text-white font-normal font-sans">
                Participate in an Online Survey
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* Nav Menu on desktop view*/}
      <div className="my-[15px]">
        <ul className="hidden customLg:flex customLg:justify-center customLg:items-center">
          {menuItems.map((item, idx) => {
            const isLastItem = idx === menuItems.length - 1;
            const itemClassName = isLastItem
              ? ""
              : "border-black border-r-2 pr-1 customXl:pr-2 customXXl:pr-4 custom2Xl:pr-5 custom3Xl:pr-7";
            return (
              <div className="relative inline-block group" key={item.to}>
                <Link
                  className={`${itemClassName} pl-1 customXl:pl-2 customXXl:pl-4 custom2Xl:pl-5 custom3Xl:pl-7 py-0 customXXl:py-1 custom3Xl:py-2 uppercase transition-all ease-in-out duration-300 delay-150 hover:text-blue_1 hover:underline`}
                  to={item.to}
                >
                  {item.title}
                </Link>
                {item.submenu && item.submenu.length > 0 && (
                  <div className="absolute left-0 z-50 invisible w-56 mt-1 transition-opacity delay-150 bg-white border border-gray-200 divide-y divide-gray-100 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible">
                    {item.submenu.map((subitem, subIdx) => (
                      <Link
                        key={subIdx}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue_1 hover:text-white"
                        to={subitem.to}
                      >
                        {subitem.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </ul>
      </div>
      {/* Toggle button */}
      <button
        className="absolute top-[30px] sm:top-[46px] right-[20px] customLg:hidden z-[22]"
        onClick={handleViewMobileMenu}
      >
        {showMobileMenu ? (
          <AiOutlineClose className="w-[32px] h-[32px]" />
        ) : (
          <AiOutlineMenu className="w-[30px] h-[30px]" />
        )}
      </button>
      {/* Nav Menu on mobile view*/}
      <div
        className={`bg-white w-[80%] overflow-hidden h-screen absolute z-[100] pl-[20px] pt-[40px] shadow-lg transition-all ease-in-out duration-300 delay-150 top-0 ${
          showMobileMenu ? "left-0" : "left-[-100%]"
        }`}
      >
        <Link to="/">
          <Logo className="w-[250px] customMd:w-[500px] customXXl:w-full" />
        </Link>
        <ul className="flex flex-col h-full bg-white pl-[20px]">
          {menuItems.map((item, idx) => {
            const isLastItem = idx === menuItems.length - 1;
            const itemClassName = isLastItem
              ? ""
              : "pr-1 customXl:pr-2 customXXl:pr-4 custom2Xl:pr-5 custom3Xl:pr-7";
            return (
              <div className="relative group block mb-[10px]" key={item.to}>
                <Link
                  className={`${itemClassName} pl-1 customXl:pl-2 customXXl:pl-4 custom2Xl:pl-5 custom3Xl:pl-7 py-0 customXXl:py-1 custom3Xl:py-2 uppercase transition-all ease-in-out duration-300 delay-150 hover:text-blue_1 hover:underline block`}
                  to={item.to}
                >
                  {item.title} {item.submenu ? ">" : ""}
                </Link>
                {item.submenu && item.submenu.length > 0 && (
                  <div className="hidden w-56 mt-1 transition-opacity delay-150 bg-white group-hover:inline-block">
                    {item.submenu.map((subitem, subIdx) => (
                      <Link
                        key={subIdx}
                        className="block px-4 py-2 text-gray-700 hover:bg-blue_1 hover:text-white"
                        to={subitem.to}
                      >
                        {subitem.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
          <div className="flex flex-row gap-[25px]">
            <Link
              className="flex items-center hover:underline bg-[#464646] w-[245px] gap-[15px] py-[4px] px-[8px] h-[55px] relative"
              to="https://blackbookmarketresearch.com/download-our-survey-app"
            >
              <div className="absolute left-[4px] w-[8px] h-[55px] bg-[#F5CB5C]" />
              <div className="bg-white w-[65px] h-[47px] flex items-center justify-center rounded-[4px]">
                <img src="./icons/mobile.svg" alt="mobile_icon" />
              </div>
              <span className="shrink-0 text-[13px] leading-[18px] w-[122px] text-white font-normal font-sans">
                Download one of our Mobile Survey Apps
              </span>
            </Link>
            <Link
              className="flex items-center hover:underline bg-[#464646] w-[245px] gap-[15px] py-[4px] px-[8px] h-[55px] relative"
              to="https://blackbookmarketresearch.com/participate-in-an-online-survey"
            >
              <div className="absolute left-[4px] w-[8px] h-[55px] bg-[#1EA1F2]" />
              <div className="bg-white w-[65px] h-[47px] flex items-center justify-center rounded-[4px]">
                <img src="./icons/pc.svg" alt="mobile_icon" />
              </div>
              <span className="shrink-0 text-[13px] leading-[17px] w-[122px] text-white font-normal font-sans">
                Participate in an Online Survey
              </span>
            </Link>
          </div>
        </ul>
      </div>
    </header>
  );
}

export default Header;
