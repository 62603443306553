import { httpClient } from "./base";

const DASHBOARD_BASE = `${process.env.REACT_APP_BACKEND_BASE_URL}/dashboard`;

export const DashboardApis = {
  fetchTopVendors: async (token) => {
    return await httpClient.authGet(token, `${DASHBOARD_BASE}/top-vendors`);
  },
  fetchCategoriesPreference: async (token) => {
    return await httpClient.authGet(
      token,
      `${DASHBOARD_BASE}/categories-preference`
    );
  },
};
