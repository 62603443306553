import React from "react";
import "react-responsive-modal/styles.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Title } from "./components/shared/base/title";
import { AppRouter } from "./containers/router";
import { WithApp, WithAuth } from "./contexts/wrappers";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={5000} />
      <WithApp>
        <WithAuth>
          <Title />
          <AppRouter />
        </WithAuth>
      </WithApp>
    </BrowserRouter>
  );
}

export default App;
