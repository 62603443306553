import React from "react";
import {
  initialState,
  UserContext,
  reducer,
  saveState,
} from "../../contexts/auth";
import { useReducerWithSubscriber } from "../../contexts/context";

export const WithAuth = ({ children }) => {
  const { state, dispatch } = useReducerWithSubscriber(
    reducer,
    initialState.state,
    [saveState]
  );
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
