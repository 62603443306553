import { useReducer, useEffect } from "react";

export function useReducerWithSubscriber(reducer, initialState, subscribers) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    for (const subscriber of subscribers) {
      subscriber(state);
    }
  }, [state, subscribers]);

  return { state, dispatch };
}
