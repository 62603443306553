import React from "react";
import { useReducerWithSubscriber } from "../../contexts/context";
import { initialState, AppStateContext, reducer } from "../../contexts/app";
import { saveState } from "../app";

export const WithApp = ({ children }) => {
  const { state, dispatch } = useReducerWithSubscriber(
    reducer,
    initialState.state,
    [saveState]
  );
  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};
