import { httpClient } from "./base";

// const AUTH_BASE = `${BASE_URL}/auth`;
const AUTH_BASE = `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`;

export const AuthApis = {
  signin: async (username, password) => {
    return await httpClient.post(`${AUTH_BASE}/login`, { username, password });
  },

  signup: async (
    firstName,
    lastName,
    email,
    companyName,
    username,
    password,
    isFound
  ) => {
    return await httpClient.post(`${AUTH_BASE}/signup`, {
      firstName: firstName,
      lastName: lastName,
      email,
      companyName: companyName,
      username,
      password,
      isFound,
    });
  },

  fetchCompanyDatas: async () => {
    return await httpClient.get(`${AUTH_BASE}/retrieve-vendors`);
  },

  inviteeSignup: async (
    firstName,
    lastName,
    email,
    companyName,
    username,
    password,
    tokens
  ) => {
    return await httpClient.post(`${AUTH_BASE}/signup`, {
      firstName: firstName,
      lastName: lastName,
      email,
      companyName: companyName,
      username,
      password,
      token: tokens,
    });
  },

  signout: async (token) => {
    return await httpClient.authPost(token, `${AUTH_BASE}/signout`);
  },

  confirm: async (token) => {
    return await httpClient.post(`${AUTH_BASE}/confirm-email`, {
      confirm_token: token,
    });
  },

  sendConfirm: async (token) => {
    return await httpClient.authGet(token, `${AUTH_BASE}/send-confirm`);
  },

  forgotPassword: async (email) => {
    return await httpClient.post(`${AUTH_BASE}/forgot-pwd`, { email });
  },

  resetPassword: async (reset_token, password) => {
    return await httpClient.authPost(
      reset_token,
      `${AUTH_BASE}/resetpassword`,
      {
        password,
      }
    );
  },
};
