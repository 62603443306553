export const firstMenu = [
  {
    id: 1,
    title: "Home",
    url: "https://blackbookmarketresearch.com/",
  },
  {
    id: 2,
    title: "About",
    url: "https://blackbookmarketresearch.com/about-us",
  },
  {
    id: 3,
    title: "Resources",
    url: "https://blackbookmarketresearch.com/resources",
  },
];

export const secondMenu = [
  {
    id: 1,
    title: "Research Store",
    url: "https://blackbookmarketresearch.com/research-store",
  },
  {
    id: 2,
    title: "Reports",
    url: "https://blackbookmarketresearch.com/reports",
  },
  {
    id: 3,
    title: "News & Social Media",
    url: "https://blackbookmarketresearch.newswire.com/",
  },
];

export const thirdMenu = [
  {
    id: 1,
    title: "Vendor Directory",
    url: "https://blackbookmarketresearch.com/directory",
  },
  {
    id: 2,
    title: "Subscriber",
    url: "https://blackbookmarketresearch.com/subscriber",
  },
  {
    id: 3,
    title: "Contact",
    url: "https://blackbookmarketresearch.com/contact",
  },
];
