import React from "react";
import { Route, Routes } from "react-router-dom";
// Router layouts
import { FullPageSpinner } from "../../components/shared/base";
import { MarketingPages } from "./marketing";
import { PrivatePages } from "./private";
import { PublicPages } from "./public";

// Auth Pages
const SignInPage = React.lazy(() => import("../../pages/auth/signin"));
const SignUpPage = React.lazy(() => import("../../pages/auth/signup"));
const FoundedPage = React.lazy(() => import("../../pages/auth/founded"));
const FollowingCategoriesPage = React.lazy(() =>
  import("../../pages/auth/following-categories")
);
const PaymentPage = React.lazy(() => import("../../pages/auth/payment"));
const VerifyPage = React.lazy(() => import("../../pages/auth/verify"));
const InviteVerifyPage = React.lazy(() =>
  import("../../pages/auth/invite-verify")
);
const ForgotPwdPage = React.lazy(() => import("../../pages/auth/forgot-pwd"));
const ResetPwdPage = React.lazy(() => import("../../pages/auth/reset-pwd"));
const PricingPlansPage = React.lazy(() =>
  import("../../pages/auth/pricing-plans")
);
const PricingPlansDetailPage = React.lazy(() =>
  import("../../pages/auth/pricing-plans-detail")
);
const SubscriptionCompletePage = React.lazy(() =>
  import("../../pages/auth/subscription-complete")
);
// Dashboard Pages
const DashboardPage = React.lazy(() => import("../../pages/dashboard"));
// old report builder page
const ReportBuilderPage = React.lazy(() =>
  import("../../pages/dashboard/report-builder")
);
const ReportBuilderEnterprisePage = React.lazy(() =>
  import("../../pages/dashboard/report-builder-enterprise")
);

// old /reports page
const SavedReportsPage = React.lazy(() =>
  import("../../pages/dashboard/reports")
);
const ReportFilterEnterprise = React.lazy(() =>
  import("../../pages/dashboard/report-filter-enterprise")
);
const SettingsPage = React.lazy(() => import("../../pages/dashboard/settings"));

const SubscribeSettingsPage = React.lazy(() =>
  import("../../pages/dashboard/subscribe-settings")
);
const SubscribeSettingsDetailPage = React.lazy(() =>
  import("../../pages/dashboard/subscribe-settings-detail")
);

const UpdateSubscriptionPlanPage = React.lazy(() =>
  import("../../pages/dashboard/update-subscription-plan")
);

const AddingCreditsPage = React.lazy(() =>
  import("../../pages/dashboard/adding-credits")
);

const AddingCreditsConfirmPage = React.lazy(() =>
  import("../../pages/dashboard/adding-credits-confirm")
);

export const AppRouter = () => {
  return (
    <Routes>
      {/* The pages where unauth users visit */}
      <Route element={<PublicPages />}>
        <Route
          path="/signin"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SignInPage />
            </React.Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SignUpPage />
            </React.Suspense>
          }
        />
        <Route
          path="/verify"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <VerifyPage />
            </React.Suspense>
          }
        />
        <Route
          path="/invite-verify"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <InviteVerifyPage />
            </React.Suspense>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <ForgotPwdPage />
            </React.Suspense>
          }
        />
        <Route
          path="/reset-pwd"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <ResetPwdPage />
            </React.Suspense>
          }
        />
        <Route
          path="/"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SignInPage />
            </React.Suspense>
          }
        />
      </Route>
      {/* The pages with no auth header/footer but with auth content */}
      <Route element={<MarketingPages />}>
        <Route
          path="/pricing-plans"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <PricingPlansPage />
            </React.Suspense>
          }
        />
        <Route
          path={`/pricing-plans-detail`}
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <PricingPlansDetailPage />
            </React.Suspense>
          }
        />
        <Route
          path={`/completion`}
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SubscriptionCompletePage />
            </React.Suspense>
          }
        />
        <Route
          path="/founded"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <FoundedPage />
            </React.Suspense>
          }
        />

        <Route
          path="/following-categories"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <FollowingCategoriesPage />
            </React.Suspense>
          }
        />

        <Route
          path="/payment"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <PaymentPage />
            </React.Suspense>
          }
        />
      </Route>
      {/* The pages where only auth users can visit*/}
      <Route path="/" element={<PrivatePages />}>
        <Route
          path="/dashboard"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <DashboardPage />
            </React.Suspense>
          }
        />
        <Route
          path="/report-builder"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <ReportBuilderPage />
            </React.Suspense>
          }
        />
        <Route
          path="/report-builder1"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <ReportBuilderEnterprisePage />
            </React.Suspense>
          }
        />
        <Route
          path="/reports"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SavedReportsPage />
            </React.Suspense>
          }
        />
        <Route
          path="/reports1"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <ReportFilterEnterprise />
            </React.Suspense>
          }
        />
        <Route
          path="/settings"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SettingsPage />
            </React.Suspense>
          }
        />
        <Route
          path="/settings/subscribe"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SubscribeSettingsPage />
            </React.Suspense>
          }
        />
        <Route
          path="/settings/subscribe/detail/:plan/:detailedPlan"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <SubscribeSettingsDetailPage />
            </React.Suspense>
          }
        />
        <Route
          path="/update-subscription-plan"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <UpdateSubscriptionPlanPage />
            </React.Suspense>
          }
        />
        <Route
          path="/adding-credits"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <AddingCreditsPage />
            </React.Suspense>
          }
        />
        <Route
          path="/adding-credits-confirm"
          element={
            <React.Suspense fallback={<FullPageSpinner />}>
              <AddingCreditsConfirmPage />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
