import { createContext, useContext, useEffect } from "react";

export function saveState(state) {
  localStorage.setItem("report", JSON.stringify(state));
}

function loadState() {
  const state = localStorage.getItem("report");
  if (!state) {
    return null;
  }

  return JSON.parse(state);
}

const savedState = loadState();

export const initialState = {
  state: savedState ?? {
    title: "",
    topVendors: [],
    topTens: [],
    categories: [],
  },
  dispatch: (action) => {},
};

export const AppStateContext = createContext(initialState);

export const useSelector = (selectorFn) => {
  const value = useContext(AppStateContext);
  return selectorFn(value.state);
};

export const useDispatch = () => {
  const value = useContext(AppStateContext);
  return value.dispatch;
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_VENDORS":
      return {
        ...state,
        topVendors: action.payload,
      };
    case "SET_TOPTENS":
      return {
        ...state,
        topTens: action.payload,
      };
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload };
    default:
      return state;
  }
}

export function useTitle(title) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SET_TITLE",
      payload: title,
    });
  }, [title, dispatch]);
}
