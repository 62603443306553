import { useEffect } from "react";
import { useAuthenticate } from "../../../../hooks/auth";
import { ReactComponent as SettingIcon } from "../../../../assets/svg/settingicon.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/svg/logouticon.svg";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";

function Header() {
  const { user, signout, getMe, tokens } = useAuthenticate();

  const handleLogout = () => {
    signout();
    // redirect to login page
    window.location.href = "/";
  };

  useEffect(() => {
    getMe(tokens);
    // after login, get user data but should be invoked after 8 seconds
    setTimeout(() => {
      getMe(tokens);
    }, 8000);
  }, [tokens, getMe]);

  return (
    <header className="w-full bg-grey_3 h-[82px] pr-[30px]">
      <div className="flex justify-end items-center gap-[25px] h-full">
        <div className="border-r-2 border-grey_8 h-full flex items-center">
          <div className="relative pr-[25px]">
            <div className="h-[40px] absolute bg-yellow_1 left-[62px] w-[5px] top-[15px] translate-y-[-50%] z-20"></div>
            <span className="w-[56px] inline-block absolute top-[15px] left-[4px] translate-y-[-50%] bg-black_1 text-white text-[12px] py-[6px] leading-[18px] rounded-[6px] z-20 pl-[4px]">
              Credit Balance
            </span>
            <div className="bg-white w-[140px] inline-block text-right h-[30px] pr-[21px] leading-[16px] rounded-[4px] shadow-table relative z-10">
              <span className="absolute right-[30px] top-[8px]">
                {user?.credits}
              </span>
              {user?.role.name === "admin" ||
              user?.role.name === "billing manager" ? (
                <div className="bg-green_2 text-center translate-x-[-30px] rounded-[4px] cursor-pointer group w-[25px] h-[25px] absolute right-[-28px] top-[2px]">
                  <Tippy content="Adding Credits">
                    <Link
                      className="text-white w-[20px] h-[20px] inline-block text-[20px] translate-y-[20%] group-hover:font-extrabold"
                      to={"/adding-credits"}
                    >
                      +
                    </Link>
                  </Tippy>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {user?.role.name === "admin" ||
        user?.role.name === "billing manager" ? (
          <div className="border-r-2 border-grey_8 pr-[25px] h-full flex items-center">
            <Link to={"/settings"}>
              <Tippy content="Settings Page">
                <SettingIcon />
              </Tippy>
            </Link>
          </div>
        ) : null}

        <div className="border-r-2 border-grey_8 pr-[25px] h-full flex items-center">
          {/* round icon */}
          <Tippy content={`${user.firstName + " " + user.lastName}`}>
            <div className="w-[40px] h-[40px] rounded-[50%] bg-blue_1 flex items-center justify-center">
              <span className="text-[12px] text-white">
                {user.firstName[0] + user.lastName[0]}
              </span>
            </div>
          </Tippy>
        </div>
        <div className="h-full flex items-center">
          <button onClick={handleLogout}>
            <Tippy content="Logout">
              <LogoutIcon />
            </Tippy>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
