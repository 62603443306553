import { useContext, useCallback } from "react";
import { AuthActions, UserContext } from "../contexts/auth";
import { AuthApis, UserApis } from "../service";

export const useSelector = (selectorFn) => {
  const value = useContext(UserContext);
  return selectorFn(value.state);
};

export const useDispatch = () => {
  const value = useContext(UserContext);
  return value.dispatch;
};

export const useAuthenticate = () => {
  const user = useSelector((state) => (state?.user ? state.user : null));
  const tokens = useSelector((state) => (state?.token ? state.token : null));
  const dispatch = useDispatch();

  const signin = useCallback(
    async (username, password, rememberMe) => {
      const { status, message, data } = await AuthApis.signin(
        username,
        password
      );

      if (status) {
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user: {
              ...data.user,
              verified:
                data.user.role.name === "admin" ? data.user.verified : true,
            },
            token: data.token,
            rememberMe: rememberMe,
          },
        });
        return { status, data };
      } else {
        throw new Error(message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const signup = useCallback(
    async (
      firstName,
      lastName,
      email,
      companyName,
      username,
      password,
      isFound
    ) => {
      const { status, message, data } = await AuthApis.signup(
        firstName,
        lastName,
        email,
        companyName,
        username,
        password,
        isFound
      );
      if (status) {
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user: {
              id: data.user._id,
              username: data.user.username,
              email: data.user.email,
              isFound: data.user.isFound,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              company: data.user.company,
              credits: data.user.credits,
              role: data.user.role,
              subscriptionId: "",
              customerId: "",
              verified: false,
              isNew: true,
            },
            token: null,
          },
        });
      } else {
        throw new Error(message);
      }
    },
    [dispatch]
  );

  const inviteeSignup = useCallback(
    async (
      firstName,
      lastName,
      email,
      companyName,
      username,
      password,
      tokens
    ) => {
      const { status, message, data } = await AuthApis.inviteeSignup(
        firstName,
        lastName,
        email,
        companyName,
        username,
        password,
        tokens
      );
      if (status) {
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user: {
              id: data.user._id,
              username: data.user.username,
              email: data.user.email,
              isFound: data.user.isFound,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              company: data.user.company,
              verified: true,
              isNew: false,
              credits: data.user.credits,
              role: data.user.role,
            },
            token: data.token,
          },
        });
      } else {
        throw new Error(message);
      }
    },
    [dispatch]
  );

  const signout = useCallback(async () => {
    dispatch({ type: AuthActions.clearUser });
  }, [dispatch]);

  const confirm = useCallback(
    async (accessToken, confirmToken) => {
      await AuthApis.confirm(confirmToken);
      const user = await UserApis.get(accessToken);

      dispatch({
        type: AuthActions.setUser,
        payload: { user },
      });
    },
    [dispatch]
  );

  const sendConfirm = useCallback(async (accessToken) => {
    await AuthApis.sendConfirm(accessToken);
  }, []);

  const forgotPass = useCallback(async (email) => {
    await AuthApis.forgotPassword(email);
  }, []);

  const resetPass = useCallback(async (token, pass) => {
    await AuthApis.resetPassword(token, pass);
  }, []);

  const verify = useCallback(
    async (token) => {
      const { status, data, message } = await UserApis.verifyAccount(token);
      if (status) {
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user: {
              id: data.user._id,
              username: data.user.username,
              email: data.user.email,
              isFound: data.user.isFound,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              company: data.user.company,
              credits: data.user.credits,
              role: data.user.role,
              verified: data.user.verified,
              isNew: data.user.isNew,
            },
            token: token,
          },
        });
        return { status, data };
      } else {
        throw new Error(message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const inviteVerify = useCallback(
    async (token) => {
      const { status, data, message } = await UserApis.verifyInvitedAccount(
        token
      );
      if (status) {
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user: {
              email: data.decoded.email,
              role: data.decoded.role,
              company: data.decoded.company,
            },
            token: data.token,
          },
        });
        return status;
      } else {
        throw new Error(message);
      }
    },
    [dispatch]
  );

  const getMe = useCallback(
    async (accessToken) => {
      const { status, data, message } = await UserApis.getMe(accessToken);

      if (status) {
        dispatch({
          type: AuthActions.setUser,
          payload: {
            user: {
              ...data.user,
            },
          },
        });
        return status;
      } else {
        throw new Error(message);
      }
    },
    [dispatch]
  );

  return {
    user,
    tokens,
    signin,
    signup,
    signout,
    confirm,
    sendConfirm,
    forgotPass,
    resetPass,
    verify,
    inviteVerify,
    inviteeSignup,
    getMe,
    dispatch,
  };
};
