import Axios from "axios";

class HttpClient {
  _accessToken = "";
  _refreshToken = "";

  static instance = null;
  static getInstance() {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }

    return HttpClient.instance;
  }

  set accessToken(token) {
    this._accessToken = token;
  }

  get accessToken() {
    return this._accessToken;
  }

  set refreshToken(token) {
    this._refreshToken = token;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  get = async (url, params = {}, headers = {}) => {
    const { data } = await Axios.get(url, { params, headers })
      .then((res) => res)
      .catch((err) => ({
        data: {
          ...err.response.data,
        },
      }));
    return data;
  };

  post = async (url, body, params = {}, headers = {}) => {
    const { data } = await Axios.post(url, body, { params, headers })
      .then((res) => res)
      .catch((err) => ({
        data: {
          ...err.response.data,
        },
      }));
    return data;
  };

  put = async (url, body, params = {}, headers = {}) => {
    const { data } = await Axios.put(url, body, { params, headers })
      .then((res) => res)
      .catch((err) => ({
        data: {
          ...err.response.data,
        },
      }));
    return data;
  };

  delete = async (url, params = {}, headers = {}) => {
    const { data } = await Axios.delete(url, { params, headers })
      .then((res) => res)
      .catch((err) => ({
        data: {
          ...err.response.data,
        },
      }));
    return data;
  };

  authGet = async (token, url, params = {}) => {
    if (!token)
      return {
        data: {
          success: false,
          message: "Token Expired",
        },
      };
    if (token.indexOf("Bearer ") === 0) {
      return await this.get(url, params, { Authorization: `${token}` })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
            window.location.reload();
          }
          return res;
        })
        .catch((err) => {
          return {
            data: {
              ...err?.response.data,
            },
          };
        });
    } else {
      return await this.get(url, params, { Authorization: `Bearer ${token}` })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
            window.location.reload();
          }
          return res;
        })
        .catch((err) => {
          return {
            data: {
              ...err?.response.data,
            },
          };
        });
    }
  };

  authPost = async (token, url, body = {}, params = {}) => {
    if (token.indexOf("Bearer ") === 0) {
      return await this.post(url, body, params, {
        Authorization: token,
      })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
            window.location.reload();
          }
          return res;
        })
        .catch((err) => ({
          data: {
            ...err.response.data,
          },
        }));
    } else {
      return await this.post(url, body, params, {
        Authorization: `Bearer ${token}`,
      })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
            window.location.reload();
          }
          return res;
        })
        .catch((err) => ({
          data: {
            ...err.response.data,
          },
        }));
    }
  };

  authPut = async (token, url, body = {}, params = {}) => {
    if (token.indexOf("Bearer ") === 0) {
      return await this.put(url, body, params, {
        Authorization: token,
      })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
          }
          return res;
        })
        .catch((err) => ({
          data: {
            ...err.response.data,
          },
        }));
    } else {
      return await this.put(url, body, params, {
        Authorization: `Bearer ${token}`,
      })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
          }
          return res;
        })
        .catch((err) => ({
          data: {
            ...err.response.data,
          },
        }));
    }
  };

  authDelete = async (token, url, params = {}) => {
    if (token.indexOf("Bearer " === 0)) {
      return await this.delete(url, params, {
        Authorization: token,
      })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
          }
          return res;
        })
        .catch((err) => ({
          data: {
            ...err.response.data,
          },
        }));
    } else {
      return await this.delete(url, params, {
        Authorization: `Bearer ${token}`,
      })
        .then((res) => {
          if (!res.success && res.message === "Token Expired") {
            sessionStorage.removeItem("auth");
            localStorage.removeItem("auth");
          }
          return res;
        })
        .catch((err) => ({
          data: {
            ...err.response.data,
          },
        }));
    }
  };
}

export const httpClient = HttpClient.getInstance();
