import React from "react";
import { Link } from "react-router-dom";
import { firstMenu, secondMenu, thirdMenu } from "./menus";

function Footer() {
  return (
    <footer className="w-full px-4 lg:px-[33px] pb-[17px]">
      <div className="flex flex-col gap-8 lg:gap-[75px] pt-10 lg:pt-[60px] pb-10 lg:pb-[42px] px-6 lg:px-[63px] rounded-xl border-4 border-white_1 bg-custom-gradient-2  h-full lg:h-[362px] shadow-card">
        <div className="flex flex-col lg:flex-row gap-y-4 items-center lg:justify-between lg:px-[50px] xl:px-[200px] w-full">
          <img
            src="./images/dashboard-logo.png"
            alt="Black_Book_logo"
            className="w-[100px] h-[100px] lg:w-[140px] lg:h-[140px]"
          />
          <div className="flex flex-col w-full sm:flex-row lg:hidden">
            <div className="flex flex-col gap-[33px] w-full mb-6 lg:mb-0">
              {firstMenu.map((menu) => (
                <Link
                  key={menu.id}
                  to={menu.url}
                  className="font-sans uppercase font-bold text-[#7B7B7B] text-xs lg:text-[15px] hover:underline"
                >
                  {menu.title}
                </Link>
              ))}
            </div>
            <div className="flex flex-col gap-[33px] w-full mb-6 lg:mb-0">
              {secondMenu.map((menu) => (
                <Link
                  key={menu.id}
                  to={menu.url}
                  className="font-sans uppercase font-bold text-[#7B7B7B] text-xs lg:text-[15px] hover:underline"
                >
                  {menu.title}
                </Link>
              ))}
            </div>
            <div className="flex flex-col gap-[33px] w-full mb-6 lg:mb-0">
              {thirdMenu.map((menu) => (
                <Link
                  key={menu.id}
                  to={menu.url}
                  className="font-sans uppercase font-bold text-[#7B7B7B] text-xs lg:text-[15px] hover:underline"
                >
                  {menu.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="hidden lg:flex flex-col gap-[33px] mb-6 lg:mb-0">
            {firstMenu.map((menu) => (
              <Link
                key={menu.id}
                to={menu.url}
                className="font-sans uppercase font-bold text-[#7B7B7B] text-xs lg:text-[15px] hover:underline"
              >
                {menu.title}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex flex-col gap-[33px] mb-6 lg:mb-0">
            {secondMenu.map((menu) => (
              <Link
                key={menu.id}
                to={menu.url}
                className="font-sans uppercase font-bold text-[#7B7B7B] text-xs lg:text-[15px] hover:underline"
              >
                {menu.title}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex flex-col gap-[33px] mb-6 lg:mb-0">
            {thirdMenu.map((menu) => (
              <Link
                key={menu.id}
                to={menu.url}
                className="font-sans uppercase font-bold text-[#7B7B7B] text-xs lg:text-[15px] hover:underline"
              >
                {menu.title}
              </Link>
            ))}
          </div>
        </div>
        <div className="border-t-[#7B7B7B] border-[1px] pt-[16px] flex lg:flex-row flex-col-reverse gap-y-4 items-center justify-between lg:px-[50px] xl:px-[200px]">
          <h3 className="text-[#7B7B7B] font-sans font-normal text-xs md:text-[16px]">
            © 2024 Blackbook. All rights reserved.
          </h3>
          <div className="flex flex-wrap gap-[18px] items-center justify-center">
            <Link
              to="https://play.google.com/store/apps/details?id=com.blackbook.survey"
              className="w-[122px] h-[41px] flex justify-center"
            >
              <img src="./images/googleplay.png" alt="googleplay icon" />
            </Link>
            <Link
              to="https://itunes.apple.com/us/app/black-book-healthcare-surveys/id1129233940?ls=1&mt=8"
              className="w-[122px] h-[41px] flex justify-center"
            >
              <img src="./images/appstore.png" alt="appstore icon" />
            </Link>
            <Link
              to="https://twitter.com/search?f=tweets&vertical=default&q=blackbookpolls&src=typd"
              className="w-[25px] h-[23px] flex justify-center mx-[25px]"
            >
              <img src="./icons/x.svg" alt="X icon" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/272910/"
              className="w-[21px] h-[21px] flex justify-center"
            >
              <img src="./icons/linkedin.svg" alt="Linkedin icon" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
