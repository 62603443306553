import { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Header from "../../components/shared/dashbord-layout/header";
import Sidebar from "../../components/shared/dashbord-layout/sidebar";
import { useAuthenticate } from "../../hooks/auth";

export const PrivatePages = () => {
  const [expand, setExpand] = useState(true);
  const { user } = useAuthenticate();
  if (!user) {
    return <Navigate to="/" replace />;
  } else if (!user.credits) {
    return <Navigate to="/pricing-plans" replace />;
  }

  // const fetchSettings = useCallback(() => {
  //   SettingsApis.getSubscriptionStatus(tokens).then((res) => {
  //     if (!res.status || !res.data.data.length) {
  //       navigate("/pricing-plans", { replace: true });
  //     }
  //   });
  // }, [tokens, navigate]);

  // useEffect(() => {
  //   fetchSettings();
  // }, [fetchSettings]);

  return (
    <div className="flex flex-row h-full">
      <Sidebar expand={expand} setExpand={setExpand} role={user.role} />
      <div className="w-full relative grow">
        <Header />
        <div
          className={`pb-[10px] ${
            expand
              ? "pl-[230px] custom2Xl:pl-[280px] custom3Xl:pl-[330px] transition-padding duration-300"
              : "transition-padding duration-300 pl-[140px]"
          }`}
        >
          <Outlet context={user} />
        </div>
      </div>
    </div>
  );
};
