import { createContext } from "react";
import CryptoJS from "crypto-js";

export const AuthActions = {
  setUser: "SET_USER",
  clearUser: "CLEAR_USER",
};

let sessionTimeoutId;

const SECRET_KEY = "your-secret-key"; // replace with your own secret key

function encryptSession(state) {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(state),
    SECRET_KEY
  ).toString();
  return ciphertext;
}

function decryptSession(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  const state = JSON.parse(plaintext);
  return state;
}

function resetSessionTimeout(sessionTimeoutInMinutes) {
  if (sessionTimeoutId) {
    clearTimeout(sessionTimeoutId);
  }

  sessionTimeoutId = setTimeout(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, sessionTimeoutInMinutes * 60 * 1000);
}

export function saveState(state) {
  const ciphertext = encryptSession(state);
  sessionStorage.setItem("auth", ciphertext);

  if (!state?.rememberMe) {
    // when state is not remembered, reset session timeout
    // Reset session timeout on user activity
    if (sessionTimeoutId) {
      clearTimeout(sessionTimeoutId);
    }

    sessionTimeoutId = setTimeout(() => {
      sessionStorage.clear();
      localStorage.clear();
    }, 60 * 60 * 1000);

    document.addEventListener("mousemove", () => {
      resetSessionTimeout(60);
    });

    document.addEventListener("keydown", () => {
      resetSessionTimeout(60);
    });
  } else {
    // when state is remembered, timeout need to cleared to prevent initial timeout running
    if (sessionTimeoutId) {
      localStorage.setItem("auth", ciphertext);
      clearTimeout(sessionTimeoutId);
    }
  }
}

function isObjEmpty(obj) {
  return JSON.stringify(obj) === "{}";
}

function loadState() {
  let ciphertext;

  ciphertext = sessionStorage.getItem("auth");

  if (!ciphertext || isObjEmpty(ciphertext)) {
    ciphertext = localStorage.getItem("auth");
    sessionStorage.setItem("auth", ciphertext);
  }

  if (!ciphertext || isObjEmpty(ciphertext)) {
    return null;
  }

  const state = decryptSession(ciphertext);
  return state;
}

const savedState = loadState();
export const initialState = {
  state: savedState ?? null,
  dispatch: (action) => {},
};

export const UserContext = createContext(initialState);

export function reducer(state, action) {
  switch (action.type) {
    case AuthActions.setUser:
      return {
        ...state,
        ...action.payload,
      };
    case AuthActions.clearUser:
      // clear session with auth key
      sessionStorage.removeItem("auth");

      return {
        ...state,
        user: null,
        token: null,
        rememberMe: null,
      };
    default:
      return state;
  }
}
