import { httpClient } from "./base";

const SAVED_REPORTS_BASE = `${process.env.REACT_APP_BACKEND_BASE_URL}/saved-reports`;

export const SavedReportApis = {
  saveReport: async (token, reportData) => {
    return await httpClient.authPost(token, SAVED_REPORTS_BASE, reportData);
  },

  // save report page
  filterSavedReports: async (token, requestQuery, metaData, searchValue) => {
    const { perPage, currentPage } = metaData;

    let url = `${SAVED_REPORTS_BASE}?page=${currentPage}&limit=${perPage}`;

    if (searchValue) {
      url += `&search=${searchValue}`;
    }

    if (requestQuery?.year?.length) {
      requestQuery.year.forEach((year) => {
        url += `&year=${year}`;
      });
    }

    if (requestQuery?.year_quarter?.length) {
      requestQuery.year_quarter.forEach((quarter) => {
        url += `&year_quarter=${quarter}`;
      });
    }

    if (requestQuery?.category) {
      url += `&category=${encodeURIComponent(requestQuery?.category)}`;
    }

    if (requestQuery?.subCategory) {
      url += `&subcategory=${encodeURIComponent(requestQuery?.subCategory)}`;
    }

    if (requestQuery?.vendor?.length) {
      requestQuery.vendor.forEach((vendor) => {
        url += `&vendor=${encodeURIComponent(vendor)}`;
      });
    }

    if (requestQuery?.product?.length) {
      requestQuery.product.forEach((product) => {
        url += `&product=${encodeURIComponent(product)}`;
      });
    }

    return await httpClient.authGet(token, url);
  },

  fetchVendorsData: async (token, reportIds) => {
    let url = SAVED_REPORTS_BASE + "/compare?";
    reportIds.forEach((reportId, idx) => {
      if (idx === 0) {
        url += `report_id=${reportId}`;
      } else {
        url += `&report_id=${reportId}`;
      }
    });

    return await httpClient.authGet(token, url);
  },
};
