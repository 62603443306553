import { httpClient } from "./base";

const USER_BASE = `${process.env.REACT_APP_BACKEND_BASE_URL}/users`;

export const UserApis = {
  get: async (token) => {
    return httpClient.authGet(token, `${USER_BASE}`);
  },

  update: async (token, payload) => {
    return httpClient.authPut(token, `${USER_BASE}`, payload);
  },

  verifyAccount: async (token) => {
    return await httpClient.authPost(token, `${USER_BASE}/verify`);
  },

  verifyInvitedAccount: async (token) => {
    return await httpClient.authGet(token, `${USER_BASE}/invite-verify`);
  },

  sendInvitation: async (token, email, role) => {
    return await httpClient.authPost(token, `${USER_BASE}/invite`, [
      {
        email: email,
        role: role,
      },
    ]);
  },

  fetchOrganizationUsers: async (token) => {
    return await httpClient.authGet(token, USER_BASE);
  },

  deleteUser: async (token, userId) => {
    return await httpClient.authDelete(token, `${USER_BASE}/${userId}`);
  },

  getMe: async (token) => {
    return await httpClient.authGet(token, `${USER_BASE}/me`);
  },
};
