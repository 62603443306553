export const SmallLoader = (props) => {
  return (
    <div className="absolute right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2">
      <div
        className={`border-t-transparent border-solid animate-spin rounded-full border-${props.color} border-4 h-[22px] w-[22px]`}
      ></div>
    </div>
  );
};

export const Loader = (props) => {
  return (
    <div className="absolute right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2">
      <div
        className={`border-t-transparent border-solid animate-spin rounded-full border-${props.color} border-4 h-[36px] w-[36px]`}
      ></div>
    </div>
  );
};

export const FullPageSpinner = () => {
  return (
    <div className="absolute right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2">
      <div className="border-t-transparent border-solid animate-spin rounded-full border-blue_1 border-8 h-[128px] w-[128px]"></div>
    </div>
  );
};
