export const extractValues = (arr) => {
  if (!arr?.length) return [];
  const res = arr.map((item) => item.value);
  return res;
};

export const kpiTippy = [
  {
    kpi: "Q1",
    value: "Strategic Alignment of Client Goals",
  },
  {
    kpi: "Q2",
    value: "Innovation & Optimization",
  },
  {
    kpi: "Q3",
    value: "Training",
  },
  {
    kpi: "Q4",
    value: "Client Relationships and Cultural Fit",
  },
  {
    kpi: "Q5",
    value: "Trust, Accountability, Transparency, Ethics",
  },
  {
    kpi: "Q6",
    value: "Breadth of Offerings, Client Types, Delivery Excellence",
  },
  {
    kpi: "Q7",
    value: "Deployment and Implementation",
  },
  {
    kpi: "Q8",
    value: "Customization",
  },
  {
    kpi: "Q9",
    value: "Integration and interfaces",
  },
  {
    kpi: "Q10",
    value: "Scalability, Client Adaptability, Flexible Pricing",
  },
  {
    kpi: "Q11",
    value: "Compensation and employee performance",
  },
  {
    kpi: "Q12",
    value: "Reliability",
  },
  {
    kpi: "Q13",
    value: "Brand Image and Marketing Communications",
  },
  {
    kpi: "Q14",
    value: "Marginal Value Adds and Modules",
  },
  {
    kpi: "Q15",
    value: "Financial Stability & managerial Viability",
  },
  {
    kpi: "Q16",
    value: "Data Security, Patient Privacy and Backup Services",
  },
  {
    kpi: "Q17",
    value: "Account Management Support and Customer Care",
  },
  {
    kpi: "Q18",
    value: "Best of Breed Technology and Process Improvement",
  },
];

export const menuItems = [
  { title: "Home", to: "https://blackbookmarketresearch.com/" },
  {
    title: "Resources",
    to: "https://blackbookmarketresearch.com/resources",
    submenu: [
      {
        title: "Vendor briefing",
        to: "https://blackbookmarketresearch.com/briefings",
      },
      {
        title: "Review Free Reports",
        to: "https://blackbookmarketresearch.com/review-free-reports",
      },
      {
        title: "Survey Key Indicators",
        to: "https://blackbookmarketresearch.com/survey-key-indicators",
      },
      {
        title: "Statistical Confidence",
        to: "https://blackbookmarketresearch.com/statistical-confidence",
      },
      {
        title: "Maximize Award Checklist",
        to: "https://blackbookmarketresearch.com/checklist-to-maximize-award",
      },
      {
        title: "Disclaimer",
        to: "https://blackbookmarketresearch.com/disclaimer",
      },
    ],
  },
  {
    title: "Research Store",
    to: "https://blackbookmarketresearch.com/research-store",
    submenu: [
      {
        title: "Search and Purchase Report",
        to: "https://blackbookmarketresearch.com/shop",
      },
    ],
  },
  {
    title: "About",
    to: "https://blackbookmarketresearch.com/about-us",
    submenu: [
      {
        title: "Team & Leadership",
        to: "https://blackbookmarketresearch.com/black-book-leadership",
      },
      {
        title: "Awards",
        to: "https://blackbookmarketresearch.com/awards",
      },
      {
        title: "Download the Current Seal",
        to: "https://blackbookmarketresearch.com/download-the-current-seal",
      },
      {
        title: "Methodology",
        to: "https://blackbookmarketresearch.com/methodology",
      },
      {
        title: "Careers",
        to: "https://blackbookmarketresearch.com/careers",
      },
      {
        title: "FAQ",
        to: "https://blackbookmarketresearch.com/FAQ",
      },
      {
        title: "Privacy Policy",
        to: "https://blackbookmarketresearch.com/privacy-policy",
      },
    ],
  },
  {
    title: "News & Social Media",
    to: "https://blackbookmarketresearch.newswire.com/",
  },
  {
    title: "Vendor directory",
    to: "https://blackbookmarketresearch.com/directory",
    submenu: [
      {
        title: "List Your Company In Black",
        to: "https://blackbookmarketresearch.com/list-your-company-in-black-book",
      },
      {
        title: "Book",
        to: "https://blackbookmarketresearch.com/list-your-company-in-black-book",
      },
    ],
  },
  {
    title: "Reports",
    to: "https://blackbookmarketresearch.com/reports",
    submenu: [
      {
        title: "Current Surveys & Survey Notification",
        to: "https://blackbookmarketresearch.com/current-surveys",
      },
      {
        title: "Reports Calendar",
        to: "https://blackbookmarketresearch.com/reports-calendar",
      },
      {
        title: "Calendar of Events",
        to: "https://blackbookmarketresearch.com/calendar-of-events",
      },
    ],
  },
  {
    title: "Subscriber",
    to: "https://blackbookmarketresearch.com/subscriber",
    submenu: [
      {
        title: "Subscriber login",
        to: "https://blackbookmarketresearch.com/subscriber-login",
      },
    ],
  },
  {
    title: "Contact",
    to: "https://blackbookmarketresearch.com/contact",
    submenu: [
      {
        title: "Download Mobile Survey App",
        to: "https://blackbookmarketresearch.com/download-our-survey-app",
      },
    ],
  },
];

export function formatPrice(price) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0, // This ensures no cents are included
  }).format(price);
}

export const inviteeRoles = [
  {
    role: "Manager",
    desc: "Can view saved reports",
    key: "manager",
  },
  {
    role: "Billing Manager",
    desc: "Can change the billing details",
    key: "billing manager",
  },
  {
    role: "Analyst",
    desc: "Can pull reports and save reports",
    key: "analyst",
  },
];
