import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/shared/auth/footer";
import Header from "../../components/shared/auth/header";
import { useAuthenticate } from "../../hooks/auth";
import { SettingsApis } from "../../service";

export const MarketingPages = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { user, tokens } = useAuthenticate();
  const navigate = useNavigate();
  const fetchSettings = useCallback(() => {
    if (!user) {
      navigate("/", { replace: true });
      return;
    }

    SettingsApis.getSubscriptionStatus(tokens).then((res) => {
      if (res.status && res.data.data.length > 0) {
        if (user?.credits > 0) {
          navigate("/dashboard", { replace: true });
        }
      }
    });
  }, [tokens, navigate, user]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return (
    <>
      <div
        className={`${
          showMobileMenu
            ? "h-screen w-full bg-black absolute z-[19] opacity-60"
            : ""
        }`}
      ></div>
      <Header
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />
      <div className="min-h-[600px] relative">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
