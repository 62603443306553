import { httpClient } from "./base";

const SETTINGS_BASE = `${process.env.REACT_APP_BACKEND_BASE_URL}/settings`;

export const SettingsApis = {
  fetchSettings: async (token) => {
    return await httpClient.authGet(token, SETTINGS_BASE);
  },
  savePreference: async (token, { category }) => {
    return await httpClient.authPut(
      token,
      `${SETTINGS_BASE}/dashboard-preference`,
      {
        category,
      }
    );
  },
  getProducts: async (token) => {
    return await httpClient.authGet(token, `${SETTINGS_BASE}/products`);
  },
  listCustomerPaymentMethods: async (token) => {
    return await httpClient.authGet(
      token,
      `${SETTINGS_BASE}/list-customer-payment-methods`
    );
  },
  getClientSecret: async (token, pricingId) => {
    return await httpClient.authPost(token, `${SETTINGS_BASE}/client-secret`, {
      pricingId,
    });
  },
  getSubscriptionStatus: async (token) => {
    return await httpClient.authGet(
      token,
      `${SETTINGS_BASE}/subscription-status`
    );
  },
  getCustomer: async (token) => {
    return await httpClient.authGet(token, `${SETTINGS_BASE}/customer`);
  },
  attachBankAccount: async (token, bankAccountToken) => {
    return await httpClient.authPost(
      token,
      `${SETTINGS_BASE}/attach-bank-account`,
      {
        bankAccountToken,
      }
    );
  },
  verifyBankAccount: async (token, { bankAccountId, amounts }) => {
    return await httpClient.authPost(
      token,
      `${SETTINGS_BASE}/verify-bank-account`,
      {
        bankAccountId,
        amounts,
      }
    );
  },
  addCredits: async (token, priceId, paymentMethodId) => {
    return await httpClient.authPost(
      token,
      `${process.env.REACT_APP_BACKEND_BASE_URL}/subscriptions/add_credits`,
      {
        priceId,
        paymentMethodId,
      }
    );
  },
  getCreditProducts: async (token) => {
    return await httpClient.authGet(token, `${SETTINGS_BASE}/credit_products`);
  },
  updateSubscription: async (token, priceId) => {
    return await httpClient.authPut(
      token,
      `${process.env.REACT_APP_BACKEND_BASE_URL}/subscriptions`,
      {
        price_id: priceId,
      }
    );
  },
};
