import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/shared/auth/footer";
import Header from "../../components/shared/auth/header";
import { useAuthenticate } from "../../hooks/auth";

export const PublicPages = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();

  const { pathname } = location;
  const { user } = useAuthenticate();

  if (user) {
    if (user.verified) {
      if (!user.company?.stripeCustomerId) {
        return <Navigate to="/pricing-plans" replace />;
      }
      return <Navigate to="/dashboard" replace />;
    }
    if (pathname === "/signin" && user.company?.stripeCustomerId) {
      return <Navigate to="/dashboard" replace />;
    }
  }

  return (
    <>
      <div
        className={`${
          showMobileMenu
            ? "h-screen w-full bg-black absolute z-[19] opacity-60"
            : ""
        }`}
      ></div>
      <Header
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />
      <div className="relative ">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
